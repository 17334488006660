type TScienceSource = {
  title: string;
  url: string;
};

type TProjectButtonStyles = {
  fontColor: string;
  background: string;
};

type TProjectLogoStyles = {
  borderRadius?: string;
};

type TContentStyles = {
  background?: string;
  backHeadeBoxShadow: string;
  cardBackground: string;
  cardBorderColor: string;
  cardShadow?: string;
  userContentBackground: string;
  cardTitleColor: string;
  cardTextColor: string;
  cardButtonBackground: string;
  cardButtonBorderRadius: string;
  graphTrailingColor: string;
  caloriesGraphColor: string;
  waterGraphColor: string;
  waterButtonBackground: string;
  waterButtonTextColor: string;
  textMarkedColor: string;
  graphTabBorderColor: string;
  profileBackground: string;
};

type TProjectStyles = {
  logo: TProjectLogoStyles;
  button: TProjectButtonStyles;
  buttonSecondary: TProjectButtonStyles;
  content: TContentStyles;
};

type TProjectConfigValues = {
  marketedTitle: string;
  logo: string;
  logoSvg: string;
  primaryColor: string;
  fontPrimaryColor: string;
  primaryBg: string;
  secondaryBg: string;
  fontInfo: string;
  inputBgColor: string;
  inputBorderColor: string;
  inputFontColor: string;
  inReview: boolean;
  sources: TScienceSource[];
  appTesterEmail: string;
  appTesterPassword: string;
  styles: TProjectStyles;
};

type TProjectConfig = {
  inflammi: TProjectConfigValues;
  mediplan: TProjectConfigValues;
  hypno: TProjectConfigValues;
};

const projectConfig: TProjectConfig = {
  inflammi: {
    marketedTitle: "Inflammi",
    logo: "logo.png",
    logoSvg: "InflammiLogoSvg.tsx",
    primaryColor: "#f6c26e",
    fontPrimaryColor: "#3d3d3d",
    primaryBg: "rgb(245, 245, 245)",
    secondaryBg: "#ffe8c2",
    fontInfo: "#848484",
    inputBgColor: "#e9edf3",
    inputBorderColor: "#e9edf3",
    inputFontColor: "#000000",
    inReview: false,
    sources: [
      {
        title: "Foods that fight inflammation",
        url: "https://www.health.harvard.edu/staying-healthy/foods-that-fight-inflammation",
      },
      {
        title: "Anti Inflammatory Diet",
        url: "https://www.hopkinsmedicine.org/health/wellness-and-prevention/anti-inflammatory-diet",
      },
      {
        title: "Anti-Inflammatory Diet Do's and Don'ts",
        url: "https://www.arthritis.org/health-wellness/healthy-living/nutrition/anti-inflammatory/anti-inflammatory-diet",
      },
      {
        title: "Quick-start guide to an anti‑inflammation diet",
        url: "https://www.health.harvard.edu/staying-healthy/quick-start-guide-to-an-antiinflammation-diet",
      },
      {
        title: "Why and How To Start an Anti-Inflammatory Diet",
        url: "https://health.clevelandclinic.org/anti-inflammatory-diet",
      },
    ],
    appTesterEmail: "app.tester@inflammiplan.com",
    appTesterPassword: "123456",
    styles: {
      logo: {
        borderRadius: undefined,
      },
      button: {
        fontColor: "#ffffff",
        background: "#f6c26e",
      },
      buttonSecondary: {
        fontColor: "#ffffff",
        background: "rgba(34, 34, 34, 0.2)",
      },
      content: {
        background: undefined,
        backHeadeBoxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        cardBackground: "#ffffff",
        cardBorderColor: "#ffffff",
        cardShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
        userContentBackground: "#ffffff",
        cardTitleColor: "#333",
        cardTextColor: "#666",
        cardButtonBackground: "none",
        cardButtonBorderRadius: "0px",
        graphTrailingColor: "#eeeeee",
        caloriesGraphColor: "#f6c26e",
        waterGraphColor: "rgb(2, 122, 255)",
        waterButtonBackground: "rgb(2, 122, 255)",
        waterButtonTextColor: "#ffffff",
        textMarkedColor: "#f6c26e",
        graphTabBorderColor: "#f6c26e",
        profileBackground: "#f6c26e",
      },
    },
  },
  mediplan: {
    marketedTitle: "MediPlan",
    logo: "logo.png",
    logoSvg: "MediLogoSvg.tsx",
    primaryColor: "rgb(63, 161, 52)",
    fontPrimaryColor: "#3d3d3d",
    primaryBg: "rgb(245, 245, 245)",
    secondaryBg: "#dafdd7",
    fontInfo: "rgb(29, 35, 38)",
    inputBgColor: "#ffffff",
    inputBorderColor: "rgb(185, 207, 204)",
    inputFontColor: "#000000",
    inReview: false,
    sources: [
      {
        title: "Mediterranean Diet",
        url: "https://my.clevelandclinic.org/health/articles/16037-mediterranean-diet",
      },
      {
        title: "Diet Review: Mediterranean Diet",
        url: "https://nutritionsource.hsph.harvard.edu/healthy-weight/diet-reviews/mediterranean-diet/",
      },
      {
        title: "What is the Mediterranean Diet?",
        url: "https://www.heart.org/en/healthy-living/healthy-eating/eat-smart/nutrition-basics/mediterranean-diet",
      },
      {
        title: "A practical guide to the Mediterranean diet",
        url: "https://www.health.harvard.edu/blog/a-practical-guide-to-the-mediterranean-diet-2019032116194",
      },
    ],
    appTesterEmail: "app.tester@getmediplan.com",
    appTesterPassword: "123456",
    styles: {
      logo: {
        borderRadius: undefined,
      },
      button: {
        fontColor: "#ffffff",
        background: "rgb(63, 161, 52)",
      },
      buttonSecondary: {
        fontColor: "#ffffff",
        background: "rgba(34, 34, 34, 0.2)",
      },
      content: {
        background: undefined,
        backHeadeBoxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        cardBackground: "#ffffff",
        cardBorderColor: "#ffffff",
        cardShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
        userContentBackground: "#ffffff",
        cardTitleColor: "#333",
        cardTextColor: "#666",
        cardButtonBackground: "none",
        cardButtonBorderRadius: "0px",
        graphTrailingColor: "#eeeeee",
        caloriesGraphColor: "rgb(63, 161, 52)",
        waterGraphColor: "rgb(2, 122, 255)",
        waterButtonBackground: "rgb(2, 122, 255)",
        waterButtonTextColor: "#ffffff",
        textMarkedColor: "#f6c26e",
        graphTabBorderColor: "rgb(63, 161, 52)",
        profileBackground: "rgb(63, 161, 52)",
      },
    },
  },
  hypno: {
    marketedTitle: "Hypno Plan",
    logo: "logo.hypno.png",
    logoSvg: "HypnoLogoSvg.tsx",
    primaryColor: "#07001c",
    fontPrimaryColor: "#ffffff",
    primaryBg: "#07001c",
    secondaryBg: "#8a4fd3",
    fontInfo: "#bec4d2",
    inputBgColor: "#07001c",
    inputBorderColor: "#33325b",
    inputFontColor: "#ffffff",
    inReview: false,
    sources: [
      {
        title: "Does Meditation Help You Lose Weight?",
        url: "https://www.webmd.com/balance/features/meditation-hypertension-and-weight-loss",
      },
      {
        title: "How to Meditate for Weight Loss",
        url: "https://www.healthline.com/health/meditation-for-weight-loss",
      },
      {
        title: "What to know about meditation for weight loss",
        url: "https://www.medicalnewstoday.com/articles/meditation-for-weight-loss",
      },
      {
        title: "Meditation for weight loss",
        url: "https://www.headspace.com/meditation/weight-loss",
      },
      {
        title: "Mediterranean Diet",
        url: "https://my.clevelandclinic.org/health/articles/16037-mediterranean-diet",
      },
      {
        title: "Diet Review: Mediterranean Diet",
        url: "https://nutritionsource.hsph.harvard.edu/healthy-weight/diet-reviews/mediterranean-diet/",
      },
      {
        title: "What is the Mediterranean Diet?",
        url: "https://www.heart.org/en/healthy-living/healthy-eating/eat-smart/nutrition-basics/mediterranean-diet",
      },
      {
        title: "A practical guide to the Mediterranean diet",
        url: "https://www.health.harvard.edu/blog/a-practical-guide-to-the-mediterranean-diet-2019032116194",
      },
      {
        title: "Foods that fight inflammation",
        url: "https://www.health.harvard.edu/staying-healthy/foods-that-fight-inflammation",
      },
      {
        title: "Anti Inflammatory Diet",
        url: "https://www.hopkinsmedicine.org/health/wellness-and-prevention/anti-inflammatory-diet",
      },
      {
        title: "Anti-Inflammatory Diet Do's and Don'ts",
        url: "https://www.arthritis.org/health-wellness/healthy-living/nutrition/anti-inflammatory/anti-inflammatory-diet",
      },
      {
        title: "Quick-start guide to an anti‑inflammation diet",
        url: "https://www.health.harvard.edu/staying-healthy/quick-start-guide-to-an-antiinflammation-diet",
      },
      {
        title: "Why and How To Start an Anti-Inflammatory Diet",
        url: "https://health.clevelandclinic.org/anti-inflammatory-diet",
      },
    ],
    appTesterEmail: "app.tester@hypnoplan.com",
    appTesterPassword: "123456",
    styles: {
      logo: {
        borderRadius: "16px",
      },
      button: {
        fontColor: "#ffffff",
        background: "linear-gradient(90deg,#5e3ab6,#8a4fd3 50%,#d16a47);",
      },
      buttonSecondary: {
        fontColor: "#ffffff",
        background: "linear-gradient(90deg,#bfb0e2,#d0b9ed 50%,#edc3b5);",
      },
      content: {
        background: "#191435",
        backHeadeBoxShadow: "0 2px 2px -2px #ffffff",
        cardBackground: "rgb(25, 20, 53)",
        cardBorderColor: "rgb(86, 81, 131)",
        cardShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
        userContentBackground: "#07001c",
        cardTitleColor: "#ffffff",
        cardTextColor: "#ffffff",
        cardButtonBackground:
          "linear-gradient(90deg,#5e3ab6,#8a4fd3 50%,#d16a47);",
        cardButtonBorderRadius: "0.5rem",
        graphTrailingColor: "#f9f9f9",
        caloriesGraphColor: "#8b5eff",
        waterGraphColor: "#34cdc2",
        waterButtonBackground:
          "linear-gradient(90deg,#8a5ffe,#4676ff 50%,#35cac1);",
        waterButtonTextColor: "#ffffff",
        textMarkedColor: "#8a4fd3",
        graphTabBorderColor: "rgb(86, 81, 131)",
        profileBackground: "rgb(25, 20, 53)",
      },
    },
  },
};

type TConfigValues = {
  apiUrl: string;
  projectName: string;
  websiteUrl: string;
  project: TProjectConfigValues;
};

export const configValues: TConfigValues = {
  apiUrl: process.env.REACT_APP_API_URL ?? "",
  projectName: process.env.REACT_APP_PROJECT_NAME ?? "",
  websiteUrl: process.env.REACT_APP_WEBSITE_URL ?? "",
  project: projectConfig[process.env.REACT_APP_PROJECT_NAME],
};
